import React, { useEffect, useState } from 'react';
import styles from './AccountIntegrationInductionModal.module.css';
import Modal from '../Modal';
import PrimaryButton from '../../Form/PrimaryButton';
import { sendAnalyticsEvent } from '../../../utils/analytics';

function AccountIntegrationInductionModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    accountIntegrationInductionModal,
    idLogin,
    getAccountIntegrationStatus,
    handleModalClose,
    proceedIdPage,
  } = props;

  useEffect(() => {
    (async () => {
      try {
        await getAccountIntegrationStatus();
      } catch (e) {
        console.log(`アカウント統合状態の取得に失敗しました; e = ${e}`);
        // エラーメッセージは表示しない
      }
    })();
  }, [getAccountIntegrationStatus, props.accountIntegrationStatus]);

  const onProceedAccountIntegration = async () => {
    sendAnalyticsEvent('account_integration_induction', 'click', 'proceed');

    try {
      setIsLoading(true);

      // ID基盤ログイン
      await idLogin();

      // アカウント統合画面を開く
      proceedIdPage('/account-integration?from_service=qa');
    } catch (e) {
      setIsLoading(false);
      console.log(`IDログインに失敗しました; e = ${e}`);
      props.alert('処理に失敗しました。時間をおいて再度お試しください。');
    } finally {
      // ポップアップクローズ／ローディングインジケーターを解除しないまま画面遷移
    }
  };

  const onProceedCancel = () => {
    sendAnalyticsEvent('account_integration_induction', 'click', 'close');
    handleModalClose();
  };

  if (!accountIntegrationInductionModal.show) {
    return null;
  } else {
    sendAnalyticsEvent('account_integration_induction', 'show', '');
  }

  const qaUrl = process.env.REACT_APP_BASE_HOST;
  const slideUrl = process.env.REACT_APP_SLIDE_URL;
  return (
    <Modal
      close={onProceedCancel}
      confirm={true}
      style={{ borderRadius: '20px' }}
    >
      <div className={styles.modal}>
        <div className={styles.modalHeading}>
          <div className={styles.logoContainer}>
            <img
              className={styles.logo}
              src="/images/antaa_icon_small.png"
              alt="Antaa"
            />
            <h1 className={styles.title}>{'QA x Slide'}</h1>
          </div>
          <h1 className={styles.heading}>※重要なお知らせ</h1>
          <h1 className={styles.heading}>アカウント統合のお願い</h1>
          <h1 className={styles.heading}>(2024年9月末まで)</h1>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.content}>
            <p>
              これまで、Antaaでは
              <a href={`${qaUrl}`} target="_blank">
                Antaa QA
              </a>
              と
              <a href={`${slideUrl}`} target="_blank">
                Antaa Slide
              </a>
              の2つのサービスを別々で提供していましたが、この度Antaaという1つのサービスに統合することにいたしました。
            </p>
            <p>
              Antaa QAとAntaa
              Slideのアカウントを連携していただくことで、より便利にサービスをお使いいただけるようになります。
            </p>
          </div>

          <div className={styles.actions}>
            <PrimaryButton
              style={{
                width: '100%',
                borderRadius: '20px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
              value="アカウント統合へ進む"
              theme={'submit'}
              onClick={onProceedAccountIntegration}
              loading={isLoading}
            />
            <div className={styles.closeText} onClick={onProceedCancel}>
              あとで行う
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AccountIntegrationInductionModal;
